/** 
 * 餐饮行业
 */
<template>
  <v-card class="cateringTrade">
    <v-banner
        single-line
        height="400"
        class="banner"
      >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/bannerCateringTrade400.jpg"
          height="100%"
          :transition="false"
          :class="imgLoad ? 'v-image__image--animation':''">
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="pa-0 max-width-1200" style="margin-top: 150px;">
          <v-card color="transparent" flat>
            <v-card-title class="white--text pa-0 font-size-50 font-weight-bold font-size-banner-title">
              餐饮行业解决方案
            </v-card-title>
            <v-card-title class="white--text part1 pa-0 mt-7 font-size-banner-tip" style="line-height:30px">
              <!-- 助力商家构建AR、VR营销场景，提升获客<br/>打破传统餐饮行业获客难、留存低的营销痛点 -->
              助力商家构建AR、VR数字化场景，提升获客<br/>打破传统餐饮行业获客难、留存低的痛点
            </v-card-title>
            <!-- <v-card-actions class="pa-0 mt-16">
              <v-btn
                  rounded
                  width="160"
                  height="50"
                  color="#ffffff"
                  class="font-size-20"
              >
                <span style="color: #333333;">立即咨询</span>
              </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-container>
      </v-img>
    </v-banner>  
    <!-- 详细介绍 -->
     <v-container class="containerStyle max-width-1200">
      <v-card class="wrap part-one d-flex justify-center" flat>
        <div class="pr-10">
          <img src="https://h5.ophyer.cn/official_website/other/eat01.png" />
        </div>
        <div class="text-wrap" style="padding-right:160px">
          <v-card class="pa-0" flat>
            <v-card-title class="con_tit pa-0 mb-5">AR扫描生成虚拟形象加深用户记忆</v-card-title>
            <v-card-text class="con_text pa-0">扫一扫，AR虚拟形象/吉祥物、LOGO在桌面显示，趣味性玩法增加用户记忆</v-card-text>
          </v-card>
        </div>
      </v-card>
      <v-card class="wrap part-one d-flex flex-no-wrap justify-center flex-row-reverse" flat>
        <div class="pl-10">
          <img src="https://h5.ophyer.cn/official_website/other/eat02.png" />
        </div>
        <div class="text-wrap" style="padding-left:160px">
          <v-card class="pa-0"  flat>
            <v-card-title class="con_tit pa-0 mb-5" >积分体系助力拉新留存</v-card-title>
            <!-- <v-card-text class="con_text pa-0">通过AR游戏、打卡、签到等营销活动获得积分，可兑换打折卡、实物奖励、赠送餐品等，增加用户存留，提升营销效果</v-card-text> -->
            <v-card-text class="con_text pa-0">通过AR游戏、打卡、签到等趣味活动获得积分，可兑换打折卡、实物奖励、赠送餐品等，增加用户存留，提升运营效果</v-card-text>
          </v-card>
        </div>
      </v-card>
      <v-card class="part-one d-flex flex-no-wrap justify-center" flat>
        <div class="pr-10">
          <img src="https://h5.ophyer.cn/official_website/other/eat03.png" />
        </div>
        <div class="text-wrap" style="padding-right:160px">
          <v-card class="pa-0" flat>
            <!-- <v-card-title class="con_tit pa-0 mb-5" >丰富的营销插件增加用户粘性</v-card-title> -->
            <v-card-title class="con_tit pa-0 mb-5" >丰富的插件增加用户粘性</v-card-title>
            <!-- <v-card-text class="con_text pa-0">传统餐饮等位容易流失潜在用户，丰富的营销插件让用户在趣味的游戏中度过漫长的等位时间</v-card-text> -->
            <v-card-text class="con_text pa-0">传统餐饮等位容易流失潜在用户，丰富的插件让用户在趣味的游戏中度过漫长的等位时间</v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-container> 
    <!-- 行业案例 -->
    <!--<v-card class="caseStyle" flat>-->
    <!--  <div class="public-title " style="padding-bottom:40px;margin-top:40px;">-->
    <!--    <p>INDUSTRY CASE</p>-->
    <!--    <div>-->
    <!--      <span>行业案例</span>-->
    <!--    </div>-->
    <!--  </div>-->
    <!--  <v-container style="max-width:1120px;margin-bottom:50px" fluid>-->
    <!--    <v-row >-->
    <!--        <v-col v-for="(card, index) in anli" :key="index" cols="3">-->
    <!--            <v-card-->
    <!--              class="mx-auto item-card"-->
    <!--              max-width="240"-->
    <!--              max-height="410"-->
    <!--              color="transparent"-->
    <!--               style="border-radius:unset;"-->
    <!--              @click="goCaseDetail(card)"-->
    <!--              flat-->
    <!--            >-->
    <!--              <v-img-->
    <!--                max-height="340"-->
    <!--                :src="card.coverImg"-->
    <!--                :aspect-ratio="9/16"-->
    <!--              >-->
    <!--              <template v-slot:placeholder>-->
    <!--                      <v-row class="fill-height ma-0" align="center" justify="center">-->
    <!--                        <v-progress-circular-->
    <!--                          indeterminate-->
    <!--                          size="80"-->
    <!--                          color="red lighten-5"-->
    <!--                        ></v-progress-circular>-->
    <!--                      </v-row>-->
    <!--              </template>-->
    <!--              </v-img>-->
    <!--              <div class="text-box">-->
    <!--                <v-card-text class="title font-size-14 font-weight-bold" v-text="card.itemTitle"></v-card-text>-->
    <!--              </div>-->
    <!--            </v-card>-->
    <!--        </v-col>-->
    <!--    </v-row>-->
    <!--  </v-container>-->
    <!--</v-card>-->
  </v-card>
</template>

<script>
import textData from '@/static/textData.js' // 合作案例
import qs from 'qs'
let _this;
  export default {
    components: {
    },
    data () {
      return {
        name: 'CateringTrade',
        bannerLoad: false,
        imgLoad: false,
        list:[
          {id:1, title:'西贝莜面村', img:'https://h5.ophyer.cn/official_website/other/eat03.png'},
          {id:1, title:'好实鲜蟹煲', img:'https://h5.ophyer.cn/official_website/other/eat03.png'},
          {id:1, title:'蔡澜茶餐厅', img:'https://h5.ophyer.cn/official_website/other/eat03.png'}
        ],
        anli:[textData.anli.mengniu,textData.anli.costa,textData.anli.canlangang,textData.anli.haoshixian]
      }
    },
    mounted(){
      _this = this;
      this.bannerLoad = true;
    },
    methods: {
      handleLoad: ()=>{
        _this.imgLoad = true;
        console.log("ewf")
      },
      goCaseDetail:(item) =>{
        let query = {
          source: 'qita',
          anli: item.self
        }
        let url = '/CaseDetail?'+qs.stringify(query)
        window.open(url, '_blank')
      }
    }
  }
</script>

<style lang="scss" scoped>
.public-title{
  margin-bottom: 0px;
  height: unset;
}
.containerStyle{
  margin-top: 45px;
  .part-one{
    margin-bottom: 21px;
    img{
      border-radius: 10px;
      width: 600px;
      height: 300px;
      object-fit: contain;
    }
    .text-wrap{
      width: 600px;
      .con_tit{
        font-size: 24px;
        color: #333333;
        line-height: 30px;
        margin-top:84px
      }
      .con_text{
        height: 40px;
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        line-height: 26px;
      }
      .banner-btn {
        margin-top: 50px;
        width: 160px;
        height: 50px;
        background-color: #0568FD;
        border-radius: 25px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
.caseStyle{
  max-width: 1200px;
  margin: 0 auto;
}

.banner{
  .part1{
    width: 400px;
    line-height: 1.5;
  }
}

  .v-window__container {
    display: flex;
    flex-direction: unset;
    justify-content: flex-start;
    flex-wrap: wrap;
    background: #f5f8fd;
    padding-bottom: 50px;
  }
  .v-window-item {
    width: 240px;
    height: 410px;
    margin: 40px 20px 0;
    .container {
      padding: 0;
    }
  }

.item-card {
    margin-bottom: 15px;
    padding: 10px;
    box-shadow: 0px 1px 14px 0px rgba(0, 60, 255, 0.08) !important;
    border-radius: 8px;
    transition: all 0.3s ease-in;
    position: relative;
    transition: all 0.3s;
  &::after {
    content: "";
    background-image: url("./../../assets/images/home/case-icon.png");
    background-size: 100%;
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 16px;
    right: 10px;
  }
  &:hover {
    box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
    transform: scale(1.04);
  }
  .text-box {
    .title {
      font-size: 14px !important;
      font-weight: 400;
      color: #333333;
      padding: 0 !important;
      margin-left: 3px;
      margin: 15px 0 8px 3px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 180px;
    }
    .tip {
      font-size: 14px !important;
      font-weight: 400 !important;
      color: #333333;
      padding: 0 !important;
      margin: 0 0 10px 3px;
    }
  }
  
}

  // // 卡片
  // .item-card {
  //   margin-bottom: 15px;
  //   padding: 10px;
  //   box-shadow: 0px 1px 14px 0px rgba(0, 60, 255, 0.08) !important;
  //   border-radius: 8px;
  //   transition: all 0.3s ease-in;
  //   position: relative;
  //   &:hover {
  //     box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
  //   }
  //   .v-image {
  //     border-radius: unset !important;
  //   }
  //   .text-box {
  //     .title {
  //       font-size: 14px !important;
  //       font-weight: 400;
  //       color: #333333;
  //       padding: 0 !important;
  //       margin-left: 3px;
  //       margin: 15px 0 8px 3px;
  //       overflow: hidden;
  //       text-overflow: ellipsis;
  //       white-space: nowrap;
  //       width: 180px;
  //     }
  //     .tip {
  //       font-size: 14px !important;
  //       font-weight: 400 !important;
  //       color: #333333;
  //       padding: 0 !important;
  //       margin: 0 0 10px 3px;
  //     }
  //   }
  //   &::after {
  //     content: "";
  //     background-image: url("./../assets/images/home/case-icon.png");
  //     background-size: 100%;
  //     width: 20px;
  //     height: 20px;
  //     position: absolute;
  //     bottom: 20px;
  //     right: 10px;
  //   }
  // }

</style>
